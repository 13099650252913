<template>
  <v-card :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row no-gutters>
        <span> Set Payment Reference/Date</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close" fab class="error" x-small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-form ref="frmPayment" v-model="valid" lazy-validation class="ref-form">
          <v-row>
            <v-col md="5" sm="4" cols="6">
              <b>Payrun Date:</b>
            </v-col>
            <v-col md="7" sm="8" cols="6"> {{ formatDate(PayRunFromDate) }} - {{ formatDate(PayRunToDate) }}</v-col>
          </v-row>
          <v-row @click="fillAmount()">
            <v-col md="5" sm="4" cols="6">
              <b>Total Amount: </b>
            </v-col>
            <v-col md="7" sm="8" cols="6"> {{ formatNumber(PayRunLCTotalPay) }} {{ PayRunExchangeCurrency }} </v-col>
          </v-row>
        </v-form>
      </v-row>
      <v-row>
        <v-col md="4">
          <TextField :rules="[rules.required]" label="Payment Refrence" v-model="model.PaymentRefNo" outlined dense></TextField>
        </v-col>
        <v-col md="3">
          <v-menu v-model="paymentDatePickerMenu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ props }">
              <TextField
                :rules="[rules.required]"
                label="Payment Date"
                v-model="formatedPaymentDate"
                readonly
                outlined
                v-bind="props"
                dense
              ></TextField>
            </template>
            <v-date-picker
              first-day-of-week="1"
              ref="picker"
              v-model="model.PaymentDate"
              @update:modelValue="paymentDatePickerMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="3">
          <CurrencyTextField
            :rules="[rules.required, rules.currency, rules.notZero]"
            dense
            outlined
            :label="`Amount ${PayRunExchangeCurrency}`"
            v-model="model.LCPaymentAmount"
            :readonly="isReadOnly"
          />
        </v-col>
        <v-col md="2" sm="2" cols="12">
          <v-btn fab x-small color="primary" class="mt-9" @click="addPayment"><v-icon>mdi-plus</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-card-text v-if="PayRunPayments">
        <v-data-table :headers="paymentListHeaders" :items="PayRunPayments" item-key="id" class="elevation-1 custom-table-row">
          <template v-slot:item.PaymentDate="{ item }">
            {{ formatDate(item.PaymentDate) }}
          </template>
          <template v-slot:item.PaymentRefNo="{ item }">
            {{ item.PaymentRefNo }}
          </template>
          <template v-slot:item.LCTotalPay="{ item }">
            {{ formatNumber(item.LCTotalPay) }} {{ item.ExchangeCurrency ? item.ExchangeCurrency : "TMN" }}
          </template>
          <template v-slot:item.Action="{ item }">
            <v-btn fab x-small color="error" class="mt-2" dark @click="removePayment(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-row>
        <v-col md="6">
          <v-btn color="primary" @click="setPaymentInfo" :loading="loading"> <v-icon>mdi-check</v-icon> Set </v-btn>
        </v-col>
        <v-col md="6" class="text-right">
          <v-btn color="error" @click="close"> <v-icon>mdi-close</v-icon> Cancel </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import { PayrunPayment } from "shared-components/src/models/PayRun";
import PayRunService from "shared-components/src/services/PayRunService";
import Utils from "shared-components/src/utils/Utils";
import rules from "shared-components/src/utils/validations";

export default defineComponent({
  props: ["selectedPayRun"],
  data() {
    return {
      rules,
      paymentDatePickerMenu: false,
      PayRunExchangeCurrency: this.selectedPayRun.ExchangeCurrency,
      PayRunLCTotalPay: this.selectedPayRun.LCTotalPay,
      PayRunFromDate: this.selectedPayRun.FromDate,
      PayRunToDate: this.selectedPayRun.ToDate,
      PayRunPayments: this.selectedPayRun.Payments,
      paymentListHeaders: [
        { title: "Payment Date", value: "PaymentDate", sortable: false },
        { title: "Payment Reference", value: "PaymentRefNo", sortable: false },
        { title: "Payment Amount", value: "LCTotalPay", sortable: false, align: "center" },
        { title: "Action", value: "Action", sortable: false, align: "center" },
      ],
      model: {
        PaymentDate: null as Date | null,
        PaymentRefNo: null as string | null,
        LCPaymentAmount: 0,
      },
      loading: false,
      valid: true,
    };
  },
  methods: {
    fillAmount() {
      this.model.LCPaymentAmount = this.PayRunLCTotalPay;
    },
    close(): void {
      this.resetForm();
      this.$emit("close");
    },
    removePayment(item: PayrunPayment) {
      if (this.PayRunPayments) {
        this.PayRunPayments = this.PayRunPayments.filter((x: any) => x != item);
      }
    },
    setLCPaymentAmount($event: any): void {
      this.model.LCPaymentAmount = Utils.currencyToNumber($event);
    },
    async addPayment() {
      const payment = {
        LCTotalPay: this.model.LCPaymentAmount,
        PaymentDate: this.model.PaymentDate,
        PaymentRefNo: this.model.PaymentRefNo,
      } as PayrunPayment;
      const isValid = await (this.$refs.frmPayment as any).validate();
      if (this.model && isValid.valid) {
        if (this.PayRunPayments) {
          this.PayRunPayments.push(payment);
        } else {
          this.PayRunPayments = [payment];
        }

        this.resetForm();
      }
    },
    resetForm() {
      if (this.$refs.frmPayment) {
        (this.$refs.frmPayment as any).reset();
      }
    },
    async setPaymentInfo(): Promise<void> {
      this.loading = true;

      if (this.model) {
        await PayRunService.setPayments(this.selectedPayRun.id, this.PayRunPayments);
      }
      this.$emit("confirmed", JSON.parse(JSON.stringify(this.model)));
    },
    formatDate(date: Date): string {
      return Utils.toVsDateFormat(date);
    },
    formatNumber(value: number): string {
      return Utils.numberToCurrency(value);
    },
  },
  computed: {
    formatedPaymentDate() {
      return this.model.PaymentDate ? Utils.toVsDateFormat(this.model.PaymentDate) : null;
    },
  },
});
</script>

<style scoped>
.ref-form {
  width: 100%;
}
</style>
