import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list_group = _resolveComponent("v-list-group")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createBlock(_component_v_list, {
    nav: "",
    class: "pt-0 main-menu-items"
  }, {
    default: _withCtx(() => [
      (_ctx.isAuthenticated && !_ctx.isHiringmanager && !_ctx.isSupport)
        ? (_openBlock(), _createBlock(_component_v_list_group, {
            key: 0,
            modelValue: _ctx.managementDefaultOpen,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.managementDefaultOpen) = $event)),
            class: "m"
          }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_list_item, _mergeProps(props, {
                "prepend-icon": "mdi-briefcase-outline",
                title: "Management"
              }), null, 16)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, {
                to: "/billableItem/list",
                "prepend-icon": "mdi-package",
                title: "Billable Items"
              }),
              _createVNode(_component_v_list_item, {
                to: "/timesheet/list",
                "prepend-icon": "mdi-history",
                title: "Timesheet"
              }),
              _createVNode(_component_v_list_item, {
                to: "/commitment/list",
                "prepend-icon": "mdi-handshake",
                title: "Commitments"
              }),
              _createVNode(_component_v_list_item, {
                to: "/commitment/listV2",
                "prepend-icon": "mdi-handshake",
                title: "Commitments V2"
              }),
              _createVNode(_component_v_list_item, {
                to: "/payRuns",
                "prepend-icon": "mdi-account-cash",
                title: "Pay Run"
              }),
              _createVNode(_component_v_list_item, {
                to: "/payRunsV2",
                "prepend-icon": "mdi-account-cash",
                title: "Pay Run V2"
              }),
              _createVNode(_component_v_list_item, {
                to: "/loans",
                "prepend-icon": "mdi-cash-multiple",
                title: "Loans"
              }),
              _createVNode(_component_v_list_item, {
                to: "/weeklyTimesheetAudit",
                "prepend-icon": "mdi-server-security",
                title: "Weekly Timesheet Audit"
              }),
              _createVNode(_component_v_list_item, {
                to: "/customers/chart",
                "prepend-icon": "mdi-file-tree",
                title: "Customers Chart"
              }),
              _createVNode(_component_v_list_item, {
                to: "/auditTrail",
                "prepend-icon": "mdi-eye-outline",
                title: "Audit Trail"
              }),
              _createVNode(_component_v_list_item, {
                to: "/publicHolidays",
                "prepend-icon": "mdi-calendar-multiple-check",
                title: "Public Holidays"
              }),
              _createVNode(_component_v_list_item, {
                to: "/repositories",
                "prepend-icon": "mdi-source-repository",
                title: "Repositories"
              }),
              _createVNode(_component_v_list_item, {
                to: "/taskTemplate",
                "prepend-icon": "mdi-calendar-check",
                title: "Task Template List"
              }),
              _createVNode(_component_v_list_item, {
                to: "/bulkImportExport",
                "prepend-icon": "mdi-swap-horizontal-bold",
                title: "Bulk Import Export"
              })
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.isAuthenticated && !_ctx.isHiringmanager && !_ctx.isSupport)
        ? (_openBlock(), _createBlock(_component_v_list_group, {
            key: 1,
            modelValue: _ctx.reportsDefaultOpen,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.reportsDefaultOpen) = $event)),
            class: "r"
          }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_list_item, _mergeProps(props, {
                "prepend-icon": "mdi-chart-line",
                title: "Reports"
              }), null, 16)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, {
                to: "/reports/timesheet-report",
                "prepend-icon": "mdi-server-security",
                title: "Timesheets Report"
              }),
              _createVNode(_component_v_list_item, {
                to: "/reports/pay-roll",
                "prepend-icon": "mdi-server-security",
                title: "PayRoll Report"
              }),
              _createVNode(_component_v_list_item, {
                to: "/reports/analytical-report",
                "prepend-icon": "mdi-chart-areaspline",
                title: "Analytics Report"
              }),
              _createVNode(_component_v_list_item, {
                to: "/reports/contribution-report",
                "prepend-icon": "mdi-google-analytics",
                title: "Contribution Report"
              }),
              _createVNode(_component_v_list_item, {
                to: "/reports/hrcommitments-report",
                "prepend-icon": "mdi-google-analytics",
                title: "HR Commitments Report"
              }),
              _createVNode(_component_v_list_item, {
                to: "/reports/expenseAnalysis",
                "prepend-icon": "mdi-google-analytics",
                title: "Expense Analysis"
              }),
              _createVNode(_component_v_list_item, {
                to: "/reports/costAnalysis",
                "prepend-icon": "mdi-cash-register ",
                title: "Cost Analysis"
              })
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.isAuthenticated && !_ctx.isHiringmanager && !_ctx.isSupport)
        ? (_openBlock(), _createBlock(_component_v_list_group, {
            key: 2,
            modelValue: _ctx.userManagementDefaultOpen,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userManagementDefaultOpen) = $event)),
            class: "u"
          }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_list_item, _mergeProps(props, {
                "prepend-icon": "mdi-account-group-outline",
                title: "User Management"
              }), null, 16)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, {
                to: "/users/list",
                "prepend-icon": "mdi-account-group",
                title: "Users List"
              }),
              _createVNode(_component_v_list_item, {
                to: "/notificationManagement",
                "prepend-icon": "mdi-bell",
                title: "Notification Management"
              }),
              _createVNode(_component_v_list_item, {
                to: "/activities/list",
                "prepend-icon": "mdi-ticket",
                title: "Activities"
              }),
              _createVNode(_component_v_list_item, {
                to: "/access/list",
                "prepend-icon": "mdi-server-security",
                title: "Accesses"
              })
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      ((_ctx.isAuthenticated || _ctx.isHiringmanager) && !_ctx.isSupport)
        ? (_openBlock(), _createBlock(_component_v_list_group, {
            key: 3,
            modelValue: _ctx.recruitmentDefaultOpen,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.recruitmentDefaultOpen) = $event)),
            class: "r"
          }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_list_item, _mergeProps(props, {
                "prepend-icon": "mdi-account-search",
                title: "Recruitment"
              }), null, 16)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, {
                to: "/job",
                "prepend-icon": "mdi-account-plus",
                title: "Job opening"
              }),
              _createVNode(_component_v_list_item, {
                to: "/search",
                "prepend-icon": "mdi-account-multiple",
                title: "Team Members"
              }),
              _createVNode(_component_v_list_item, {
                to: "/task",
                "prepend-icon": "mdi-calendar-check",
                title: "My Tasks"
              }),
              _createVNode(_component_v_list_item, {
                "prepend-icon": "mdi-inbox",
                title: "Inbox"
              })
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.isAuthenticated && !_ctx.isHiringmanager && !_ctx.isSupport)
        ? (_openBlock(), _createBlock(_component_v_list_group, {
            key: 4,
            modelValue: _ctx.teamRolesDefaultOpen,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.teamRolesDefaultOpen) = $event)),
            class: "t"
          }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_list_item, _mergeProps(props, {
                "prepend-icon": "mdi-account-tie-outline",
                title: "Team Roles"
              }), null, 16)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, {
                to: "/customers",
                "prepend-icon": "mdi-voice",
                title: "Customers"
              }),
              _createVNode(_component_v_list_item, {
                to: "/projectLeads",
                "prepend-icon": "mdi-voice",
                title: "Project Leads"
              }),
              _createVNode(_component_v_list_item, {
                to: "/portfolioManagers",
                "prepend-icon": "mdi-voice",
                title: "Portfolio Managers"
              }),
              _createVNode(_component_v_list_item, {
                to: "/clients",
                "prepend-icon": "mdi-voice",
                title: "Clients"
              }),
              _createVNode(_component_v_list_item, {
                to: "/tdms",
                "prepend-icon": "mdi-voice",
                title: "TDMs"
              }),
              _createVNode(_component_v_list_item, {
                to: "/lineManagers",
                "prepend-icon": "mdi-account-star",
                title: "Line Managers"
              })
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.isAuthenticated && !_ctx.isHiringmanager)
        ? (_openBlock(), _createBlock(_component_v_list_group, {
            key: 5,
            modelValue: _ctx.networkDefaultOpen,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.networkDefaultOpen) = $event)),
            class: "n"
          }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_list_item, _mergeProps(props, {
                "prepend-icon": "mdi-network-outline",
                title: "Network"
              }), null, 16)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, {
                to: "/vm",
                "prepend-icon": "mdi-server",
                title: "VM"
              }),
              _createVNode(_component_v_list_item, {
                to: "/vm/support",
                "prepend-icon": "mdi-face-agent",
                title: "Support"
              })
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_divider),
      (!_ctx.isAuthenticated)
        ? (_openBlock(), _createBlock(_component_v_list_item, {
            key: 6,
            to: "/login",
            "prepend-icon": "mdi-login",
            title: "Login"
          }))
        : _createCommentVNode("", true),
      (_ctx.isAuthenticated)
        ? (_openBlock(), _createBlock(_component_v_list_item, {
            key: 7,
            to: "/logout",
            "prepend-icon": "mdi-logout",
            title: "Logout"
          }))
        : _createCommentVNode("", true),
      (_ctx.isAuthenticated)
        ? (_openBlock(), _createBlock(_component_v_list_item, {
            key: 8,
            to: "/resetPassword",
            "prepend-icon": "mdi-update",
            title: "Reset Password"
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}