<template>
  <v-row>
    <v-col md="6" sm="12" cols="12">
      <v-form ref="frmLogin">
        <v-card>
          <v-card-title> Sign In </v-card-title>
          <v-card-text>
            <v-col md="12">
              <TextField
                outlined
                v-model="model.userName"
                prepend-icon="mdi-account"
                label="User Name"
                type="text"
                class="login-field"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col md="12">
              <TextField
                outlined
                v-model="model.password"
                prepend-icon="mdi-key"
                label="Password"
                type="password"
                class="login-field"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col md="12">
              <v-checkbox v-model="rememberMe" label="Remember me"> </v-checkbox>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-col md="12">
              <v-btn :disabled="loading" :loading="loading" color="primary" variant="elevated" @click="login" prepend-icon="mdi-check">Login</v-btn>
              <v-spacer :class="isMobile ? '' : 'd-none'"></v-spacer>
              <v-btn :class="isMobile ? 'mt-5 secondary_btn' : 'ml-5 secondary_btn'" variant="elevated" to="/resetPassword" prepend-icon="mdi-update">
                Reset password
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UserInfo from "shared-components/src/models/UserInfo";
import UserService from "../services/UserService";
import store from "@/store";
import { Profile } from "shared-components/src/definitions/config";
import { CountryApi } from "shared-components/src/services/openApi";
import { mapGetters } from "vuex";

export default defineComponent({
  data() {
    return {
      loading: false,
      model: {
        userName: localStorage.username,
        password: localStorage.password,
      },
      rememberMe: localStorage.rememberme === "true" ? true : false,
      rules: {
        required: (value: any) => !!value || "Required.",
      },
    };
  },
  methods: {
    setUserInfo(userInfo: UserInfo): void {
      store.dispatch("setUserInfo", { userInfo });
    },
    async login(): Promise<void> {
      const isValid = await (this.$refs.frmLogin as any).validate();
      if (isValid.valid) {
        this.loading = true;
        if (this.model.userName) {
          this.model.userName = this.model.userName.toLowerCase().trim();
        }

        if (this.rememberMe) {
          localStorage.username = this.model.userName;
          localStorage.password = this.model.password;
        } else {
          localStorage.username = "";
          localStorage.password = "";
        }
        localStorage.rememberme = this.rememberMe;
        try {
          const userInfo = await UserService.login(this.model);
          if (userInfo.id) {
            this.setUserInfo(userInfo);
            const returnUrl = this.$route.query.returnUrl as string;
            this.$router.push(returnUrl || "/");
            if (!this.getCountries || this.getCountries.length === 0) {
              new CountryApi().getCountries().then(v => { if (v.status === 200) store.commit("ADD_COUNTRIES", v.data) });
            }
          } else {
            store.dispatch("showErrorMessage", "The UserName or Password is wrong")
          }
          this.loading = false;
        } catch (error: any) {
          store.dispatch("showErrorMessage", error.response.data.message)
        } finally {
          this.loading = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters(["getCountries"]),
    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },
  },
});
</script>
<style scoped>
.m10 {
  margin: 0 10px;
}
</style>
