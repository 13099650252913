import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, withKeys as _withKeys } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_ComboBoxField = _resolveComponent("ComboBoxField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_pay_run_form = _resolveComponent("pay-run-form")!
  const _component_loading_component = _resolveComponent("loading-component")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_set_refrence = _resolveComponent("set-refrence")!
  const _component_PayRunDetail = _resolveComponent("PayRunDetail")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createVNode(_component_v_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { "no-gutters": "" }, {
              default: _withCtx(() => [
                _cache[10] || (_cache[10] = _createElementVNode("span", { class: "mt-3 mb-5" }, "Pay Runs", -1)),
                _createVNode(_component_v_divider, {
                  class: "mx-4",
                  inset: "",
                  vertical: ""
                }),
                _createVNode(_component_v_spacer)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, { class: "mt-3" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  md: "4",
                  sm: "6",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ComboBoxField, {
                      modelValue: _ctx.selectedTeammember,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTeammember) = $event)),
                      loading: _ctx.tmLoading,
                      disabled: _ctx.tmLoading,
                      items: _ctx.teammemberSortList,
                      firstInactiveId: _ctx.firstInactiveTeammemberId,
                      "item-title": "FullName",
                      "item-value": "Id",
                      outlined: "",
                      label: "Teammember",
                      placeholder: "Select Teammember"
                    }, null, 8, ["modelValue", "loading", "disabled", "items", "firstInactiveId"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.selectedTeammember)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_v_card, {
                    class: "mt-3",
                    loading: _ctx.loading || _ctx.tmLoading,
                    disabled: _ctx.loading || _ctx.tmLoading
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { "col-6": "" }, {
                                default: _withCtx(() => _cache[11] || (_cache[11] = [
                                  _createTextVNode(" Past Pay Runs ")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                "col-6": "",
                                class: "text-end"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_btn, {
                                    color: "primary",
                                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAddOrEditDialog(false)))
                                  }, {
                                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                                      _createTextVNode("New Pay Run")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_data_table_server, {
                        headers: _ctx.payrunListHeaders,
                        items: _ctx.pasPayRuns,
                        "item-key": "id",
                        class: "elevation-1 custom-table-row",
                        loading: _ctx.loading,
                        page: _ctx.page,
                        "items-per-page": _ctx.numberOfPages,
                        "items-length": _ctx.totalItemsCount,
                        "onUpdate:options": _cache[2] || (_cache[2] = (event) => _ctx.tableOptions = event)
                      }, {
                        "item.Actions": _withCtx(({ item }) => [
                          _createVNode(_component_v_menu, {
                            bottom: "",
                            right: ""
                          }, {
                            activator: _withCtx(({ props }) => [
                              _createVNode(_component_v_icon, _mergeProps({ small: "" }, props, { title: "Action" }), {
                                default: _withCtx(() => _cache[13] || (_cache[13] = [
                                  _createTextVNode(" mdi-dots-vertical")
                                ])),
                                _: 2
                              }, 1040)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_v_list, { density: "compact" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list_item, {
                                    onClick: ($event: any) => (_ctx.showViewPayRun(item)),
                                    class: "d-flex align-center"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item_title, null, {
                                        default: _withCtx(() => _cache[14] || (_cache[14] = [
                                          _createTextVNode("View PayRun")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]),
                                  _createVNode(_component_v_list_item, {
                                    onClick: ($event: any) => (_ctx.showPayRunPDF(item)),
                                    class: "d-flex align-center"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item_title, null, {
                                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                                          _createTextVNode("View PayRun PDF")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]),
                                  _createVNode(_component_v_list_item, {
                                    onClick: ($event: any) => (_ctx.setPayFor(item)),
                                    disabled: _ctx.loadingPayrun ? true : false
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item_title, null, {
                                        default: _withCtx(() => _cache[16] || (_cache[16] = [
                                          _createTextVNode(" Enter Payment ")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick", "disabled"]),
                                  (!item.AdminApproved || !item.Payments?.length)
                                    ? (_openBlock(), _createBlock(_component_v_list_item, {
                                        key: 0,
                                        onClick: ($event: any) => (_ctx.edit(item)),
                                        disabled: _ctx.loadingPayrun ? true : false
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_list_item_title, null, {
                                            default: _withCtx(() => _cache[17] || (_cache[17] = [
                                              _createTextVNode(" Edit ")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick", "disabled"]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        "item.PayFrom": _withCtx(({ item }) => [
                          _createTextVNode(_toDisplayString(_ctx.formatDate(item.FromDate)), 1)
                        ]),
                        "item.PayTo": _withCtx(({ item }) => [
                          _createTextVNode(_toDisplayString(_ctx.formatDate(item.ToDate)), 1)
                        ]),
                        "item.PaymentMethodType": _withCtx(({ item }) => [
                          (item.PaymentMethodType)
                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                key: 0,
                                color: "primary",
                                onClick: ($event: any) => (_ctx.showPaymentMethodDetailPopup(item))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.getPaymentMethodNameByNumber(item.PaymentMethodType)), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            : _createCommentVNode("", true)
                        ]),
                        "item.PayAmount": _withCtx(({ item }) => [
                          _createTextVNode(_toDisplayString(_ctx.formatNumber(item.LCTotalPay)) + " " + _toDisplayString(item.ExchangeCurrency ? item.ExchangeCurrency : "TMN"), 1)
                        ]),
                        "item.PaymentReference": _withCtx(({ item }) => [
                          _createElementVNode("span", {
                            innerHTML: _ctx.getPaymentRefAndDate(item)
                          }, null, 8, _hoisted_2)
                        ]),
                        _: 1
                      }, 8, ["headers", "items", "loading", "page", "items-per-page", "items-length"])
                    ]),
                    _: 1
                  }, 8, ["loading", "disabled"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512), [
      [_vShow, !_ctx.pageLoading]
    ]),
    (_openBlock(), _createBlock(_component_pay_run_form, {
      viewPayRun: _ctx.viewPayRun,
      key: `payrun-form-v${_ctx.keyVersion}`,
      dateArray: [_ctx.model.FromDate, _ctx.model.ToDate],
      selectedPayRun: _ctx.selectedPayRun,
      isEditMode: _ctx.isEditMode,
      showAddOrEdit: _ctx.showAddOrEdit,
      selectedTeammember: _ctx.selectedTeammember,
      paymentMethodDetail: _ctx.htmlText,
      pasPayRuns: _ctx.pasPayRuns,
      onSetAddEditMode: _ctx.setAddEditMode
    }, null, 8, ["viewPayRun", "dateArray", "selectedPayRun", "isEditMode", "showAddOrEdit", "selectedTeammember", "paymentMethodDetail", "pasPayRuns", "onSetAddEditMode"])),
    (_ctx.pageLoading)
      ? (_openBlock(), _createBlock(_component_loading_component, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.showPaymentMethodDetail)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 1,
          modelValue: _ctx.showPaymentMethodDetail,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showPaymentMethodDetail) = $event)),
          persistent: "",
          onKeydown: _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.showPaymentMethodDetail = false), ["esc"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_toolbar, {
                  dark: "",
                  color: "primary"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      icon: "",
                      dark: "",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showPaymentMethodDetail = false))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[18] || (_cache[18] = [
                            _createTextVNode("mdi-close")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_toolbar_title, null, {
                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                        _createTextVNode("Payment method")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_spacer)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", { innerHTML: _ctx.htmlText }, null, 8, _hoisted_3)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_dialog, {
      persistent: "",
      modelValue: _ctx.showSetRefrence,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showSetRefrence) = $event)),
      "max-width": "900",
      onKeydown: _cache[8] || (_cache[8] = _withKeys(($event: any) => (_ctx.showSetRefrence = false), ["esc"]))
    }, {
      default: _withCtx(() => [
        (_ctx.showSetRefrence)
          ? (_openBlock(), _createBlock(_component_set_refrence, {
              key: 0,
              selectedPayRun: _ctx.selectedPayRun,
              onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showSetRefrence = false)),
              onConfirmed: _ctx.setPaymentConfirmed
            }, null, 8, ["selectedPayRun", "onConfirmed"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (_ctx.selectedPayRunPDF)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: `PDF-Modal-${_ctx.pdfVersion}`,
          "max-width": "800",
          modelValue: _ctx.showPdf,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.showPdf) = $event)),
          persistent: "",
          onKeydown: _withKeys(_ctx.closePdfDialog, ["esc"])
        }, {
          default: _withCtx(() => [
            (_ctx.selectedPayRunPDF)
              ? (_openBlock(), _createBlock(_component_PayRunDetail, {
                  key: `PDF-${_ctx.pdfVersion}`,
                  model: _ctx.selectedPayRunPDF,
                  isForAdmin: true,
                  payrunAuditTrail: _ctx.payrunAuditTrail,
                  onConfirmed: _ctx.payRunConfirmed,
                  onClose: _ctx.closePdfDialog
                }, null, 8, ["model", "payrunAuditTrail", "onConfirmed", "onClose"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue", "onKeydown"]))
      : _createCommentVNode("", true)
  ]))
}