<template>
  <v-card :loading="loading" :disabled="loading">
    <v-card-title>
      <div class="card-title">
        <div class="card-title-left">Billable Item List</div>
        <div class="card-title-right">
          <v-btn class="primary_btn" prepend-icon="mdi-plus" @click="showNewBillableItem = true">New Billable Item</v-btn>
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <!-- <v-row>
        <v-col class="filters pb-0" cols="12" md="4" sm="3" xs="12">
          <ComboBoxField
            :items="users"
            item-title="displayName"
            item-value="id"
            v-model="selectedUsers"
            label="Users"
            :loading="userLoading"
            multiple
            outlined
            dense
          ></ComboBoxField>
        </v-col>
        <v-col class="filters pb-0" cols="12" md="3" sm="3" xs="12">
          <DateInputField
            first-day-of-week="1"
            v-model="startDate"
            label="From Date"
            @update:modelValue="startDatePickerMenu = false"
            hide-actions
            placeholder="Select Date"
            prepend-icon=""
          ></DateInputField>
        </v-col>
        <v-col class="filters pb-0" cols="12" md="3" sm="3" xs="12">
          <DateInputField
            first-day-of-week="1"
            v-model="endDate"
            @update:modelValue="endDatePickerMenu = false"
            hide-actions
            placeholder="Select Date"
            prepend-icon=""
            label="To Date"
          >
          </DateInputField>
        </v-col>
        <v-col class="filters pb-0 mt-9" md="1">
          <v-btn class="secondary" @click="clearFilters()"> <v-icon>mdi-close</v-icon> Clear </v-btn>
        </v-col>
      </v-row> -->
    </v-card-text>
    <v-card-text>
      <v-data-table :items="billableItems" :loading="loading" :headers="headers" fixed-header>
        <template v-slot:item.Action="{ item }">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn color="black" variant="text" size="small" v-bind="props" icon="mdi-dots-vertical" />
            </template>
            <v-card>
              <v-list>
                <v-list-item title="Edit" @click="editBillableItem(item)" />
              </v-list>
            </v-card>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
  <v-dialog v-model="showNewBillableItem" persistent max-width="600px" @keydown.esc="closeNewBillingItemPopup">
    <NewBillableItem @Close="closeNewBillingItemPopup" @Save="createNewBillingItem" :billableItem="selectedBillableItem" />
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { BillableItemApi, BillableItemModel } from "shared-components/src/services/openApi";
import NewBillableItem from "./NewBillableItem.vue";
import store from "@/store";

export default defineComponent({
  components: { NewBillableItem },
  data() {
    return {
      showNewBillableItem: false,
      loading: false,
      billableItems: [] as BillableItemModel[],
      selectedBillableItem: null as BillableItemModel | null,
      headers: [
        { title: "Name", key: "Name", sortable: false },
        { title: "Code", key: "Code", sortable: true },
        { title: "Interval", key: "Interval", sortable: false },
        { title: "Fee", key: "Fee", sortable: false },
        { title: "Type", key: "Type", sortable: true },
        { title: "Action", key: "Action", sortable: false, width: "100px" },
      ],
    };
  },
  methods: {
    async createNewBillingItem(model: BillableItemModel) {
      if (this.selectedBillableItem) {
        this.billableItems[this.billableItems.findIndex((item) => item.Id == model.Id)] = model;
        this.selectedBillableItem = null;
        store.dispatch("showSuccessMessage", "Billable Item Updated Successfuly");
      } else {
        this.billableItems.push(model);
        store.dispatch("showSuccessMessage", "Billable Item Created Successfuly");
      }
      this.showNewBillableItem = false;
    },
    editBillableItem(item: BillableItemModel) {
      this.selectedBillableItem = { ...item };
      this.showNewBillableItem = true;
    },
    closeNewBillingItemPopup() {
      this.selectedBillableItem = null;
      this.showNewBillableItem = false;
    },
    clearFilters() {},
    async loadBillableItems() {
      (this.billableItems as any) = (await new BillableItemApi().getBillableItems()).data;
    },
  },
  async mounted() {
    this.loading = true;
    await this.loadBillableItems();
    this.loading = false;
  },
});
</script>
